import React from "react";
import HeroImage from "../../../asset/HeroImages.png";
import { FaInstagram, FaGithub, FaLinkedin } from "react-icons/fa6";

export const About = () => {
  return (
    <section id="section-about">
      <div className="flex flex-col h-screen bg-white  w-5/6 mx-auto justify-end rounded-b-3xl border">
        <div className="flex flex-col  md:flex-row md:h-5/6 ">
          <div className="flex flex-col justify-end w-4/5 mx-auto md:w-2/5 border-b md:border-none">
            <img src={HeroImage} alt="" className="bg-cover bg-center" />
          </div>
          <div className="flex flex-col justify-center md:w-3/5 p-2 md:p-6 text-center md:text-left">
            <p
              className="text-lg sm:text-xl md:text-4xl font-normal text-gray-600 md:pr-4 my-4"
              data-aos="fade-up"
              data-aos-duration="2000">
              Front-end developer expertise in{" "}
              <span className=" text-yellow-500 font-semibold">Javascript</span>{" "}
              with{" "}
              <span className=" text-blue-500 font-semibold">React JS</span> for
              WEB and iOS with SwiftUI.
            </p>
            <p
              className="text-gray-500 text-sm md:text-base md:pr-4 md:my-4"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="300">
              A software development specialist, 1+ year experience in IOS
              mobile front-end with Swift and SwiftUi. Additionally, I have 3+
              years of experience in web front-end development using HTML, CSS,
              and JavaScript, along with proficiency in React.js. My skills
              extend to backend technologies such as PHP and Laravel
            </p>
            <div className="flex flex-row gap-4 md:gap-8 my-4 justify-center md:justify-start">
              <a
                href="https://github.com/aliffarisqi"
                className="cursor-pointer text-xl md:text-2xl hover:shadow-sm"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-delay="400">
                <FaGithub />
              </a>
              <a
                href="https://www.linkedin.com/in/bayualiffarisqi/"
                className="cursor-pointer text-xl md:text-2xl hover:shadow-sm"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-delay="400">
                <FaLinkedin />
              </a>
              <a
                href="https://www.instagram.com/aliffarisqi_/"
                className="cursor-pointer text-xl md:text-2xl hover:shadow-sm"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-delay="400">
                <FaInstagram />
              </a>
            </div>
            <p
              className="text-center md:text-left text-2xl font-bold"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="500">
              . . .
            </p>
          </div>
        </div>
      </div>
      ;
    </section>
  );
};
