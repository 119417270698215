import React from "react";
import { FaBriefcase, FaCalendarDay } from "react-icons/fa6";
import LogoUAD from "../../../asset/logoUAD.png";
import LogoFlin from "../../../asset/LogoFlin.png";
import LogoApple from "../../../asset/LogoApple.jpeg";
import LogoTE from "../../../asset/LogoTE.webp";

export const Experience = () => {
  return (
    <section id="section-experience">
      <div className="flex flex-col pb-5 md:py-10 w-5/6 mx-auto rounded-3xl items-center">
        <div className="flex w-full h-full flex-col justify-center  gap-4">
          {/* SISI KIRI */}
          <div className="flex flex-col justify-center items-center pt-6 md:pt-0">
            <div data-aos="fade-up" data-aos-duration="2000">
              <FaBriefcase className="text-2xl md:text-5xl text-gray-500 my-4" />
            </div>
            <span
              className="text-lg sm:text-xl md:text-4xl  text-slate-700 "
              data-aos="fade-up"
              data-aos-duration="2000">
              Working Experience
            </span>
            <p
              className="text-center md:text-left text-2xl font-bold my-4"
              data-aos="fade-up"
              data-aos-duration="2000">
              . . .
            </p>
          </div>
          {/* SISI KANAN */}
          <div className="flex flex-col gap-4 md:gap-6 justify-center items-center md:px-8 md:w-3/5 mx-auto">
            <div
              className="flex flex-row gap-4 md:gap-6 border  border-slate-200 bg-white rounded-xl p-2 md:p-4 w-full items-center"
              data-aos="fade-up"
              data-aos-duration="2000">
              <img
                src={LogoApple}
                className="object-cover w-10 h-10 md:h-16 md:w-16 rounded-full p-1 border-2 border-flinneutral-black4 bg-flinprimary-normal"
                alt=""
              />
              <div className="flex flex-col text-gray-500 justify-center gap-2">
                <span className="text-sm md:text-base font-semibold">
                  Apple Developer Academy
                </span>
                <span className="text-xs md:text-sm">Junior developer</span>
                <span className=" flex text-xs md:text-sm text-gray-500 mt-1 sm:hidden flex-row gap-2 ">
                  <FaCalendarDay />
                  2023
                </span>
              </div>
              <span className="hidden md:flex text-xs md:text-sm text-gray-500 mt-1 flex-row items-center gap-2 ml-auto">
                <FaCalendarDay />
                2023
              </span>
            </div>
            <div
              className="flex flex-row gap-4 md:gap-6 border border-slate-200 bg-white rounded-xl p-2 md:p-4 w-full items-center "
              data-aos="fade-up"
              data-aos-duration="2000">
              <img
                src={LogoFlin}
                className="object-cover w-10 h-10 md:h-16 md:w-16 rounded-full p-1 border-2 border-flinneutral-black4 bg-flinprimary-normal"
                alt=""
              />
              <div className="flex flex-col text-gray-500 justify-center gap-2">
                <span className="text-sm md:text-base font-semibold">
                  CV. Cahaya Inovasi Digital
                </span>
                <span className="text-xs md:text-sm">
                  Front-End Web developer
                </span>
                <span className="text-xs md:text-sm text-gray-500 mt-1 sm:hidden flex flex-row  gap-2 ">
                  <FaCalendarDay />
                  2022 - 2023
                </span>
              </div>
              <span className="text-xs md:text-sm text-gray-500 mt-1 hidden sm:flex flex-row items-center gap-2 ml-auto">
                <FaCalendarDay />
                2022 - 2023
              </span>
            </div>
            <div
              className="flex flex-row gap-4 md:gap-6 border border-slate-200 bg-white rounded-xl p-2 md:p-4 w-full items-center"
              data-aos="fade-up"
              data-aos-duration="2000">
              <img
                src={LogoTE}
                className="object-cover w-10 h-10 md:h-16 md:w-16 rounded-full p-1 border-2 border-flinneutral-black4 bg-flinprimary-normal"
                alt=""
              />
              <div className="flex flex-col text-gray-500 justify-center gap-2">
                <span className="text-sm md:text-base font-semibold">
                  PT. Time Excelindo
                </span>
                <span className="text-xs md:text-sm">
                  Full-Stack Web Developer
                </span>
                <span className="text-xs md:text-sm text-gray-500 mt-1 sm:hidden flex flex-row items-center gap-2">
                  <FaCalendarDay />
                  2021 - 2022
                </span>
              </div>
              <span className="text-xs md:text-sm text-gray-500 mt-1 hidden sm:flex flex-row  gap-2 ml-auto">
                <FaCalendarDay />
                2021 - 2022
              </span>
            </div>
            <div
              className="flex flex-row gap-4 md:gap-6 border border-slate-200 bg-white rounded-xl p-2 md:p-4 w-full items-center"
              data-aos="fade-up"
              data-aos-duration="2000">
              <img
                src={LogoUAD}
                className="object-cover w-10 h-10 md:h-16 md:w-16 rounded-full p-1 border-2 border-flinneutral-black4 bg-flinprimary-normal"
                alt=""
              />
              <div className="flex flex-col text-gray-500 justify-center gap-2">
                <span className="text-sm md:text-base font-semibold">
                  Universitas Ahmad Dahlan
                </span>
                <span className="text-xs md:text-sm">
                  Informatics Engineering Laboratory Assistant
                </span>
                <span className="text-xs md:text-sm text-gray-500 mt-1 flex sm:hidden flex-row  gap-2 ">
                  <FaCalendarDay />
                  2019 - 2021
                </span>
              </div>
              <span className="text-xs md:text-sm text-gray-500 mt-1 sm:flex hidden flex-row items-center gap-2 ml-auto">
                <FaCalendarDay />
                2019 - 2021
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
