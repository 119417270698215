import React from "react";
import { Home } from "../pages/Home/Home";
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";
import { Footer } from "./Footer";
export const Layout = () => {
  let Links = [
    { name: "About", linkaddres: "section-about" },
    { name: "Experience", linkaddres: "section-experience" },
    { name: "Portofolio", linkaddres: "section-portofolio" },
    // { name: "Tech", linkaddres: "section-tech-stack" },
  ];
  return (
    <div className="md:bg-[#F7F8FD] w-full">
      {/* Header */}
      <div className="fixed w-screen z-10">
        <div className="flex-1 flex  mt-4 justify-center">
          <ul className="flex flex-row shadow-md bg-white rounded-full px-2 py-3 items-center">
            {Links.map((link) => (
              <li key={link.name}>
                <LinkScroll
                  activeClass="active"
                  to={link.linkaddres}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="text-gray-400 cursor-pointer px-3 py-2 rounded-full text-xs md:text-base"
                  activeStyle={{
                    backgroundColor: "#f2f4fc",
                    color: "#000000",
                  }}>
                  {link.name}
                </LinkScroll>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div>
        <Home />
      </div>
      {/* <div className="flex h-screen shadow-sm border w-5/6 mx-auto"></div> */}
      <div className="w-full mt-20 bg-white">
        <Footer />
      </div>
    </div>
  );
};
