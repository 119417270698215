import React, { useEffect } from "react";
import { About } from "./section/About";
import { Experience } from "./section/Experience";
import { TechStack } from "./section/TechStack";
import { Portofolio } from "./section/Portofolio";
import AOS from "aos";
import "aos/dist/aos.css";
export const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <About />
      <Experience />
      <Portofolio />
      {/* <TechStack /> */}
    </div>
  );
};
