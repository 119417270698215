import React from "react";
import { FaCodeMerge, FaUser } from "react-icons/fa6";
import ImmPP from "../../../asset/imm-pp.svg";
import Reglab from "../../../asset/reglab-pp.svg";
import WWDC from "../../../asset/WWDC.svg";
import Alica from "../../../asset/Alica.svg";
import SiapBisa from "../../../asset/siapbisa.svg";
import Telemedicine from "../../../asset/telemedicine.svg";

export const Portofolio = () => {
  return (
    <section id="section-portofolio">
      <div className="flex flex-col pb-5 md:py-10 w-5/6 mx-auto rounded-3xl items-center my-6">
        <div className="flex w-full flex-col gap-4">
          <div className="flex flex-col justify-center items-center pt-6 md:pt-0">
            <div data-aos="fade-up" data-aos-duration="2000">
              <FaCodeMerge className="text-2xl md:text-5xl text-gray-500 my-4" />
            </div>
            <span
              className="text-lg sm:text-xl md:text-4xl  text-slate-700 "
              data-aos="fade-up"
              data-aos-duration="2000">
              Portofolio
            </span>
            <p
              className="text-center md:text-left text-2xl font-bold my-4"
              data-aos="fade-up"
              data-aos-duration="2000">
              . . .
            </p>
          </div>
          {/* SISI KIRI */}

          <div className="gap-4 md:gap-6 justify-center items-center md:px-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
              <a
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-delay="300"
                href="https://www.flindigital.com/projects/siap-bisa">
                <div className="rounded-2xl bg-white hover:shadow-lg  border h-full pb-4">
                  <div className="p-2 md:p-4">
                    <img
                      src={SiapBisa}
                      className="object-cover rounded-xl"
                      alt=""
                    />
                  </div>
                  <div className="pb-2 px-2 md:pb-4 md:px-4">
                    <div className="font-bold text-sm md:text-base mb-2">
                      Siap Bisa
                    </div>
                    <p className="text-gray-500 text-xs md:text-sm line-clamp-2 ">
                      Web Information System for inventory Sekretariat DPRD Kab.
                      Murung Raya
                    </p>
                  </div>
                  <div className="flex flex-row gap-2 text-gray-500 mb-4 px-2 md:px-4 text-sm items-center">
                    <FaUser />
                    <span className="font-bold">Role :</span>
                    <span>Frontend Web Developer</span>
                    <span className="border py-0.5 rounded-full bg-sky-600 text-center text-white text-xs ml-auto px-2">
                      2023
                    </span>
                  </div>
                  <div className="grid grid-cols-3 md:grid-cols-4 px-2 md:px-4 gap-2">
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      React Js
                    </span>
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      Tailwind
                    </span>
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      Javascript
                    </span>
                  </div>
                </div>
              </a>
              <a
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-delay="300"
                href="https://www.flindigital.com/projects/imm">
                <div className="rounded-2xl bg-white hover:shadow-lg  border h-full pb-4">
                  <div className="p-2 md:p-4">
                    <img
                      src={ImmPP}
                      className="object-cover rounded-xl"
                      alt=""
                    />
                  </div>
                  <div className="pb-2 px-2 md:pb-4 md:px-4">
                    <div className="font-bold text-sm md:text-base mb-2">
                      IMM Management Membership
                    </div>
                    <p className="text-gray-500 text-xs md:text-sm line-clamp-2 ">
                      Web Information System for Membership management IMM
                      Yogyakarta
                    </p>
                  </div>
                  <div className="flex flex-row gap-2 text-gray-500 mb-4 px-2 md:px-4 text-sm items-center">
                    <FaUser />
                    <span className="font-bold">Role :</span>
                    <span>Frontend Web Developer</span>
                    <span className="border py-0.5 rounded-full bg-red-700 text-center text-white text-xs ml-auto px-2">
                      2023
                    </span>
                  </div>
                  <div className="grid grid-cols-3 md:grid-cols-4 px-2 md:px-4 gap-2">
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      SCSS
                    </span>
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      Bootsrap
                    </span>
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      Laravel
                    </span>
                  </div>
                </div>
              </a>
              <a
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-delay="400"
                href="https://www.flindigital.com/projects/reglab">
                <div className="rounded-2xl bg-white hover:shadow-lg  border h-full pb-4">
                  <div className="p-2 md:p-4">
                    <img
                      src={Reglab}
                      className="object-cover rounded-xl"
                      alt=""
                    />
                  </div>
                  <div className="pb-2 px-2 md:pb-4 md:px-4">
                    <div className="font-bold text-sm md:text-base mb-2">
                      Practicum Information System - TIF UAD
                    </div>
                    <p className="text-gray-500 text-xs md:text-sm line-clamp-2 ">
                      Web Information system for practicum in UAD Informatics
                      engineering
                    </p>
                  </div>
                  <div className="flex flex-row gap-2 text-gray-500 mb-4 px-2 md:px-4 text-sm items-center">
                    <FaUser />
                    <span className="font-bold">Role :</span>
                    <span>Frontend Developer</span>
                    <span className="border py-0.5 rounded-full bg-blue-500 text-center text-white text-xs ml-auto px-2">
                      2023
                    </span>
                  </div>
                  <div className="grid grid-cols-3 md:grid-cols-4 px-2 md:px-4 gap-2">
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      CSS
                    </span>
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      Laravel
                    </span>
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      MySql
                    </span>
                  </div>
                </div>
              </a>
              <a
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-delay="200"
                href="https://medium.com/@bayualiev/assistive-language-interface-76a0c1893526">
                <div className="rounded-2xl bg-white hover:shadow-lg  border h-full pb-4">
                  <div className="p-2 md:p-4">
                    <img
                      src={Alica}
                      className="object-cover rounded-xl"
                      alt=""
                    />
                  </div>
                  <div className="pb-2 px-2 md:pb-4 md:px-4">
                    <div className="font-bold text-sm md:text-base mb-2">
                      ALICA AAC APP
                    </div>
                    <p className="text-gray-500 text-xs md:text-sm line-clamp-2 ">
                      AAC App featuring a PECS system that aids individuals who
                      are unable to use speech for communication across various
                      contexts and with different communication partners
                    </p>
                  </div>
                  <div className="flex flex-row gap-2 text-gray-500 mb-4 px-2 md:px-4 text-sm items-center">
                    <FaUser />
                    <span className="font-bold">Role :</span>
                    <span>iOS Developer</span>
                    <span className="border py-0.5 rounded-full bg-purple-400 text-center text-white text-xs ml-auto px-2">
                      2023
                    </span>
                  </div>
                  <div className="grid grid-cols-3 md:grid-cols-4 px-2 md:px-4 gap-2">
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      AiVoice
                    </span>
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      SwiftUi
                    </span>
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      SwiftData
                    </span>
                  </div>
                </div>
              </a>
              <a
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-delay="100"
                href="https://medium.com/@bayualiev/rescue-endemic-wildlife-simple-educational-game-with-swiftui-a43559b9195">
                <div className="rounded-2xl bg-white hover:shadow-lg  border h-full pb-4">
                  <div className="p-2 md:p-4">
                    <img
                      src={WWDC}
                      className="object-cover rounded-xl"
                      alt=""
                    />
                  </div>
                  <div className="pb-2 px-2 md:pb-4 md:px-4">
                    <div className="font-bold text-sm md:text-base mb-2">
                      Game For Apple WWDC 23
                    </div>
                    <p className="text-gray-500 text-xs md:text-sm line-clamp-2 ">
                      Playgrounds application for WWDC23 submission that develop
                      by Swift programming language and SwiftUi framework by
                      Apple.
                    </p>
                  </div>
                  <div className="flex flex-row gap-2 text-gray-500 mb-4 px-2 md:px-4 text-sm items-center">
                    <FaUser />
                    <span className="font-bold">Role :</span>
                    <span>iOS Developer</span>
                    <span className="border py-0.5 rounded-full bg-yellow-200 text-center text-xs ml-auto px-2">
                      2023
                    </span>
                  </div>
                  <div className="grid grid-cols-3 md:grid-cols-4 px-2 md:px-4 gap-2">
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      Swift
                    </span>
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      SwiftUi
                    </span>
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      Figma
                    </span>
                  </div>
                </div>
              </a>
              <a
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-delay="200"
                href="https://www.flindigital.com/projects/telemedicine-web">
                <div className="rounded-2xl bg-white hover:shadow-lg  border h-full pb-4">
                  <div className="p-2 md:p-4">
                    <img
                      src={Telemedicine}
                      className="object-cover rounded-xl"
                      alt=""
                    />
                  </div>
                  <div className="pb-2 px-2 md:pb-4 md:px-4">
                    <div className="font-bold text-sm md:text-base mb-2">
                      Telemedicine
                    </div>
                    <p className="text-gray-500 text-xs md:text-sm line-clamp-2 ">
                      Telemedicine is an innovative solution application for
                      website-based hypertension management.
                    </p>
                  </div>
                  <div className="flex flex-row gap-2 text-gray-500 mb-4 px-2 md:px-4 text-sm items-center">
                    <FaUser />
                    <span className="font-bold">Role :</span>
                    <span>Fullstack Web Developer</span>
                    <span className="border py-0.5 rounded-full bg-green-600 text-center text-white text-xs ml-auto px-2">
                      2021
                    </span>
                  </div>
                  <div className="grid grid-cols-3 md:grid-cols-4 px-2 md:px-4 gap-2">
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      Bootsrap
                    </span>
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      Laravel
                    </span>
                    <span className="border py-0.5 rounded-full text-center text-gray-500 text-xs">
                      Php
                    </span>
                  </div>
                </div>
              </a>
            </div>
          </div>
          {/* SISI KANAN */}
        </div>
      </div>
    </section>
  );
};
