import {
  BrowserRouter as Router,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import { Layout } from "./layout/Layout";
import { Fragment } from "react";
import ScrollToTop from "./pages/Home/ScrollToTop";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Fragment>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout />}></Route>
          </Routes>
        </Fragment>
      </BrowserRouter>
    </div>
  );
}

export default App;
